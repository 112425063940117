import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { PercentageListUrl } from '../../common/Constants';
import { FaGlobe } from 'react-icons/fa';

function Header() {

  const [expanded, setExpanded] = useState(false);
  const handleNavToggle = () => {
    setExpanded(!expanded);
  };

  const brandName = "Percentage Calculator";
  const homeTitle = "Home";
  const percentageListTitle = "Percentage Calculation from 1 to 100";
  const aboutName = "About";
  const lang = "en";

  const navMenuStyle = {
    direction: lang === "ar" ? "rtl" : "ltr"
  };

  const brandUrl = lang === "ar" ? "/" : `/${lang}/`;
  const aboutUrl = `/${lang}/about/`;

  // Use the PercentageListUrlAr constant to construct the URL
  const percentageListTitleURL = `/${lang}/${PercentageListUrl}/`;

  return (
    <>
      <Navbar style={{ backgroundColor: '#43314A' }} variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href={brandUrl}>{brandName}</Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleNavToggle}
          />
          <Navbar.Collapse id="responsive-navbar-nav" in={expanded}>
            <Nav className="ms-auto" style={navMenuStyle}>
              <Nav.Link href={brandUrl}>{homeTitle}</Nav.Link>
              <Nav.Link href={percentageListTitleURL}>{percentageListTitle}</Nav.Link>
              <Nav.Link href={aboutUrl}>{aboutName}</Nav.Link>
                <Nav.Link href="/languages/" style={{ color: 'white' }}>
                  <FaGlobe size={20} style={{ color: 'white' }} />
                </Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
    </>
  );
}

export default Header;
