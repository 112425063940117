import React from 'react';
import { WebsiteUrl, LangaugesUrl } from './common/Constants'
import Helmet from './common/Helmet.js';

const TITLE = 'Select Website Language to Calculate Percentage';
const DESCRIPTION = 'Select the website language to calculate percentages from 1 to 100 in Arabic, Chinese, Dutch, English, French, German, Hindi, Italian, Japanese, Korean, Portuguese, Russian, Spanish, and Turkish, and more...';
const KEYWORDS = 'percentage calculator, percentage, calculator, calculate percentage, percentage from 1 to 100, percentage of a number';
const lang = "en"
const url = WebsiteUrl
const OG_URL = `${WebsiteUrl}/${LangaugesUrl}/`;

const flagStyle = { width: '125px', height: '75px', margin: '5px' };
const rowStyle = { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' };

const LanguageSelector = () => {
  return (
    <div className='container' style={{ padding: '20px', backgroundColor: 'lightgray' }}>
      <Helmet
        TITLE={TITLE}
        WEBSITE_URL={url}
        DESCRIPTION={DESCRIPTION}
        KEYWORDS={KEYWORDS}
        OG_URL={OG_URL}
        LANG={lang}
      />
      <div className='flag-row' style={rowStyle}>
        <a href="/en/" title="English">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
            alt="English"
            style={flagStyle}
          />
        </a>
        <a href="/" title="Arabic">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
            alt="Arabic"
            style={flagStyle}
          />
        </a>
        <a href="/fr/" title="French">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
            alt="French"
            style={flagStyle}
          />
        </a>
        <a href="/de/" title="German">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg"
            alt="German"
            style={flagStyle}
          />
        </a>
      </div>
      <div className='flag-row' style={rowStyle}>
        <a href="/sv/" title="Swedish">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg"
            alt="Swedish"
            style={flagStyle}
          />
        </a>
        <a href="/bn/" title="Bengali">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg"
            alt="Bengali"
            style={flagStyle}
          />
        </a>
        <a href="/pt/" title="Portuguese">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg"
            alt="Portuguese"
            style={flagStyle}
          />
        </a>
        <a href="/it/" title="Italian">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg"
            alt="Italian"
            style={flagStyle}
          />
        </a>
      </div>
      <div className='flag-row' style={rowStyle}>
        <a href="/zh/" title="Mandarin">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg"
            alt="Mandarin"
            style={flagStyle}
          />
        </a>
        <a href="/id/" title="Indonesian">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg"
            alt="Indonesian"
            style={flagStyle}
          />
        </a>
        <a href="/es/" title="Spanish">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg"
            alt="Spanish"
            style={flagStyle}
          />
        </a>
        {/* Polish  */}
        <a href="/pl/" title="Polish">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg"
            alt="Polish"
            style={flagStyle}
          />
        </a>
        {/* add urdu */}
        <a href="/ur/" title="Urdu">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg"
            alt="Urdu"
            style={flagStyle}
          />
        </a>
        {/* add Russia */}
        <a href="/ru/" title="Russian">
          <img
            src="https://upload.wikimedia.org/wikipedia/en/f/f3/Flag_of_Russia.svg"
            alt="Russian"
            style={flagStyle}
          />
        </a>
        {/* Add additional flags here */}
      </div>
    </div>
  );
};

export default LanguageSelector;
