import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { PercentageListUrl } from './common/Constants.js';
import HeaderAr from "./ar/home/header.js";
import HeaderEn from "./en/home/header.js";
import HeaderFr from "./fr/home/header.js";
import HeaderDe from "./de/home/header.js";
import HeaderSv from "./sv/home/header.js";
import HeaderBn from "./bn/home/header.js";
import HeaderPt from "./pt/home/header.js";
import HeaderIt from "./it/home/header.js";
import HeaderZh from "./zh/home/header.js";
import HeaderId from "./id/home/header.js";
import HeaderEs from "./es/home/header.js";
import HeaderPl from "./pl/home/header.js";
import HeaderUr from "./ur/home/header.js";
import HeaderRu from "./ru/home/header.js";
import Langauges from "./Languages.js";

// Arabic pages
const HomePageAr = lazy(() => import("./ar/home/home.js"));
const PercentageListAr = lazy(() => import("./ar/percentage/list.js"));
const PercentageComponentAr = lazy(() => import("./ar/percentage/percentage.js"));
const Privacy = lazy(() => import("./ar/privacy.js"));
const AboutAr = lazy(() => import("./ar/about.js"));
const ContactUs = lazy(() => import("./ar/contact-us.js"));

// english pages
const HomePageEn = lazy(() => import("./en/home/home.js"));
const PercentageListEn = lazy(() => import("./en/percentage/list.js"));
const PercentageComponentEn = lazy(() => import("./en/percentage/percentage.js"));
const AboutEn = lazy(() => import("./en/about.js"));


// French pages
const HomePageFr = lazy(() => import("./fr/home/home.js"));
const PercentageListFr = lazy(() => import("./fr/percentage/list.js"));
const PercentageComponentFr = lazy(() => import("./fr/percentage/percentage.js"));
const AboutFr = lazy(() => import("./fr/about.js"));

// German pages
const HomePageDe = lazy(() => import("./de/home/home.js"));
const PercentageListDe = lazy(() => import("./de/percentage/list.js"));
const PercentageComponentDe = lazy(() => import("./de/percentage/percentage.js"));
const AboutDe = lazy(() => import("./de/about.js"));

// Swedish pages
const HomePageSv = lazy(() => import("./sv/home/home.js"));
const PercentageListSv = lazy(() => import("./sv/percentage/list.js"));
const PercentageComponentSv = lazy(() => import("./sv/percentage/percentage.js"));
const AboutSv = lazy(() => import("./sv/about.js"));

// Bengali pages
const HomePageBn = lazy(() => import("./bn/home/home.js"));
const PercentageListBn = lazy(() => import("./bn/percentage/list.js"));
const PercentageComponentBn = lazy(() => import("./bn/percentage/percentage.js"));
const AboutBn = lazy(() => import("./bn/about.js"));

// Portuguese pages
const HomePagePt = lazy(() => import("./pt/home/home.js"));
const PercentageListPt = lazy(() => import("./pt/percentage/list.js"));
const PercentageComponentPt = lazy(() => import("./pt/percentage/percentage.js"));
const AboutPt = lazy(() => import("./pt/about.js"));

// Italian pages
const HomePageIt = lazy(() => import("./it/home/home.js"));
const PercentageListIt = lazy(() => import("./it/percentage/list.js"));
const PercentageComponentIt = lazy(() => import("./it/percentage/percentage.js"));
const AboutIt = lazy(() => import("./it/about.js"));

// Mandarin pages
const HomePageZh = lazy(() => import("./zh/home/home.js"));
const PercentageListZh = lazy(() => import("./zh/percentage/list.js"));
const PercentageComponentZh = lazy(() => import("./zh/percentage/percentage.js"));
const AboutZh = lazy(() => import("./zh/about.js"));

// Indonesian pages
const HomePageId = lazy(() => import("./id/home/home.js"));
const PercentageListId = lazy(() => import("./id/percentage/list.js"));
const PercentageComponentId = lazy(() => import("./id/percentage/percentage.js"));
const AboutId = lazy(() => import("./id/about.js"));

// Spanish pages
const HomePageEs = lazy(() => import("./es/home/home.js"));
const PercentageListEs = lazy(() => import("./es/percentage/list.js"));
const PercentageComponentEs = lazy(() => import("./es/percentage/percentage.js"));
const AboutEs = lazy(() => import("./es/about.js"));

// polish pages
const HomePagePl = lazy(() => import("./pl/home/home.js"));
const PercentageListPl = lazy(() => import("./pl/percentage/list.js"));
const PercentageComponentPl = lazy(() => import("./pl/percentage/percentage.js"));
const AboutPl = lazy(() => import("./pl/about.js"));

// Urdu pages
const HomePageUr = lazy(() => import("./ur/home/home.js"));
const PercentageListUr = lazy(() => import("./ur/percentage/list.js"));
const PercentageComponentUr = lazy(() => import("./ur/percentage/percentage.js"));
const AboutUr = lazy(() => import("./ur/about.js"));

// Russian pages
const HomePageRu = lazy(() => import("./ru/home/home.js"));
const PercentageListRu = lazy(() => import("./ru/percentage/list.js"));
const PercentageComponentRu = lazy(() => import("./ru/percentage/percentage.js"));
const AboutRu = lazy(() => import("./ru/about.js"));


const routes = [
  // Arabic pages
  {
    // ar about
    path: "/ar/about/",
    element: <AboutAr />
  },
  {
    // ar Privacy
    path: `/ar/privacy/`,
    element: <Privacy />
  },
  {
    // ar list
    path: `/ar/${PercentageListUrl}/`,
    element: <PercentageListAr />
  },
  {
    // ar percentage
    path: "/ar/percent_calculator/:percentage/",
    element: <PercentageComponentAr />
  },
  {
    // ar contact us
    path: `/ar/contact-us/`,
    element: <ContactUs />
  },
  // ******** Arabic pages end ******** //
  // English pages
  {
    // en about
    path: "/en/about/",
    element: <AboutEn />
  },

  {
    // en list
    path: `/en/${PercentageListUrl}/`,
    element: <PercentageListEn />
  },
  {
    // en percentage
    path: "/en/percent_calculator/:percentage/",
    element: <PercentageComponentEn />
  },

  // ******** English pages end ******** //

  // French pages
  {
    // fr about
    path: "/fr/about/",
    element: <AboutFr />
  },

  {
    // fr list
    path: `/fr/${PercentageListUrl}/`,
    element: <PercentageListFr />
  },
  {
    // fr percentage
    path: "/fr/percent_calculator/:percentage/",
    element: <PercentageComponentFr />
  },

  // ******** French pages end ******** //

  // German pages
  {
    // de about
    path: "/de/about/",
    element: <AboutDe />

  },
  {
    // de list
    path: `/de/${PercentageListUrl}/`,
    element: <PercentageListDe />
  },
  {
    // de percentage
    path: "/de/percent_calculator/:percentage/",
    element: <PercentageComponentDe />
  },
  // ******** German pages end ******** //
  // Swedish pages
  {
    // sv about
    path: "/sv/about/",
    element: <AboutSv />

  },
  {
    // sv list
    path: `/sv/${PercentageListUrl}/`,
    element: <PercentageListSv />
  },
  {
    // sv percentage
    path: "/sv/percent_calculator/:percentage/",
    element: <PercentageComponentSv />
  },
  // ******** Swedish pages end ******** //
  // Bengali pages
  {
    // bn about
    path: "/bn/about/",
    element: <AboutBn />

  },
  {
    // bn list
    path: `/bn/${PercentageListUrl}/`,
    element: <PercentageListBn />
  },
  {
    // bn percentage
    path: "/bn/percent_calculator/:percentage/",
    element: <PercentageComponentBn />
  },
  // ******** Bengali pages end ******** //
  // Portuguese pages
  {
    // pt about
    path: "/pt/about/",
    element: <AboutPt />
  },
  {
    // pt list
    path: `/pt/${PercentageListUrl}/`,
    element: <PercentageListPt />
  },
  {
    // pt percentage
    path: "/pt/percent_calculator/:percentage/",
    element: <PercentageComponentPt />

  },
  // ******** Portuguese pages end ******** //
  // Italian pages
  {
    // it about
    path: "/it/about/",
    element: <AboutIt />

  },
  {
    // it list
    path: `/it/${PercentageListUrl}/`,
    element: <PercentageListIt />
  },
  {
    // it percentage
    path: "/it/percent_calculator/:percentage/",
    element: <PercentageComponentIt />
  },
  // ******** Italian pages end ******** //

  // Mandarin pages
  {
    // zh  about
    path: "/zh/about/",
    element: <AboutZh />

  },
  {
    // it list
    path: `/zh/${PercentageListUrl}/`,
    element: <PercentageListZh />
  },
  {
    // it percentage
    path: "/zh/percent_calculator/:percentage/",
    element: <PercentageComponentZh />
  },
  // ******** Indonesian pages end ******** //

  // Indonesian pages
  {
    // id  about
    path: "/id/about/",
    element: <AboutId />

  },
  {
    // id list
    path: `/id/${PercentageListUrl}/`,
    element: <PercentageListId />
  },
  {
    // id percentage
    path: "/id/percent_calculator/:percentage/",
    element: <PercentageComponentId />
  },
  // ******** Indonesian pages end ******** //

  // Spanish pages
  {
    // es  about
    path: "/es/about/",
    element: <AboutEs />

  },
  {
    // es list
    path: `/es/${PercentageListUrl}/`,
    element: <PercentageListEs />
  },
  {
    // id percentage
    path: "/es/percent_calculator/:percentage/",
    element: <PercentageComponentEs />
  },
  // ******** Spanish pages end ******** //


  // Polish pages
  {
    // pl  about
    path: "/pl/about/",
    element: <AboutPl />

  },
  {
    // pl list
    path: `/pl/${PercentageListUrl}/`,
    element: <PercentageListPl />
  },
  {
    // pl percentage
    path: "/pl/percent_calculator/:percentage/",
    element: <PercentageComponentPl />
  },
  // ******** Polish pages end ******** //


  // Urdu pages
  {
    // ur  about
    path: "/ur/about/",
    element: <AboutUr />

  },
  {
    // ur list
    path: `/ur/${PercentageListUrl}/`,
    element: <PercentageListUr />
  },
  {
    // ur percentage
    path: "/ur/percent_calculator/:percentage/",
    element: <PercentageComponentUr />
  },
  // ******** Urdu pages end ******** //

   // Russian pages
   {
    // ru  about
    path: "/ru/about/",
    element: <AboutRu />

  },
  {
    // ur list
    path: `/ru/${PercentageListUrl}/`,
    element: <PercentageListRu />
  },
  {
    // ur percentage
    path: "/ru/percent_calculator/:percentage/",
    element: <PercentageComponentRu />
  },
  // ******** Russian pages end ******** //
];

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  // Check if the current URL includes '/ar/' or is the root path '/'
  const isArabicOrRoot = location.pathname === '/' || location.pathname.startsWith('/ar/') || location.pathname.startsWith('/languages/');
  const isEnglish = location.pathname.startsWith('/en/');
  const isFrench = location.pathname.startsWith('/fr/');
  const isGerman = location.pathname.startsWith('/de/');
  const isSwedish = location.pathname.startsWith('/sv/');
  const isBanglish = location.pathname.startsWith('/bn/');
  const isPortuguese = location.pathname.startsWith('/pt/');
  const isItalian = location.pathname.startsWith('/it/');
  const isMandarin = location.pathname.startsWith('/zh/');
  const isIndonesian = location.pathname.startsWith('/id/');
  const isSpanish = location.pathname.startsWith('/es/');
  const isPolish = location.pathname.startsWith('/pl/');
  const isUrdu = location.pathname.startsWith('/ur/');
  const isRussian = location.pathname.startsWith('/ru/');
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="container">
        {isArabicOrRoot && <HeaderAr />}
        {isEnglish && <HeaderEn />}
        {isFrench && <HeaderFr />}
        {isGerman && <HeaderDe />}
        {isSwedish && <HeaderSv />}
        {isBanglish && <HeaderBn />}
        {isPortuguese && <HeaderPt />}
        {isItalian && <HeaderIt />}
        {isMandarin && <HeaderZh />}
        {isIndonesian && <HeaderId />}
        {isSpanish && <HeaderEs />}
        {isPolish && <HeaderPl />}
        {isUrdu && <HeaderUr />}
        {isRussian && <HeaderRu />}


        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            />
          ))}
          <Route path="/" element={<HomePageAr />} />
          <Route path="*" element={<HomePageAr />} />
          <Route path="/en/" element={<HomePageEn />} />
          <Route path="/fr/" element={<HomePageFr />} />
          <Route path="/de/" element={<HomePageDe />} />
          <Route path="/sv/" element={<HomePageSv />} />
          <Route path="/bn/" element={<HomePageBn />} />
          <Route path="/pt/" element={<HomePagePt />} />
          <Route path="/it/" element={<HomePageIt />} />
          <Route path="/zh/" element={<HomePageZh />} />
          <Route path="/id/" element={<HomePageId />} />
          <Route path="/es/" element={<HomePageEs />} />
          <Route path="/pl/" element={<HomePagePl />} />
          <Route path="/ur/" element={<HomePageUr />} />
          <Route path="/ru/" element={<HomePageRu />} />
          <Route path="/languages/" element={<Langauges />} />

        </Routes>

      </div>
    </Suspense>
  );
}

export default App;
