import { Helmet, HelmetProvider } from 'react-helmet-async';

function SharedHelmet({ TITLE, DESCRIPTION, WEBSITE_URL, KEYWORDS, OG_URL, IMAGE, LANG }) {
    if (!OG_URL.endsWith('/')) {
        OG_URL += '/';
    }
    IMAGE = IMAGE ? WEBSITE_URL + IMAGE : null;
    document.documentElement.lang = LANG || 'en';
    return (
        <HelmetProvider>
            <Helmet
                onChangeClientState={(newState) => {

                    const metaDescription = document.querySelector('meta[name="description"]');
                    if (metaDescription) {
                        metaDescription.setAttribute('content', DESCRIPTION || '');
                    }
                    const metaKeywords = document.querySelector('meta[name="keywords"]');
                    if (metaKeywords) {
                        metaKeywords.setAttribute('content', KEYWORDS || '');
                    }
                    const metaOG_URL = document.querySelector('meta[property="og:url"]');
                    if (metaOG_URL) {
                        metaOG_URL.setAttribute('content', OG_URL || '');
                    }
                    const metaOG_DESCRIPTION = document.querySelector('meta[property="og:description"]');
                    if (metaOG_DESCRIPTION) {
                        metaOG_DESCRIPTION.setAttribute('content', DESCRIPTION || '');
                    }
                    const metaOG_Title = document.querySelector('meta[property="og:title"]');
                    if (metaOG_Title) {
                        metaOG_Title.setAttribute('content', TITLE || '');
                    }
                    const metaOG_Image = document.querySelector('meta[property="og:image"]');
                    if (metaOG_Image && IMAGE) {
                        metaOG_Image.setAttribute('content', IMAGE || '');
                    }
                    const CANONICAL_URL = document.querySelector('link[rel="canonical"]');
                    if (CANONICAL_URL) {
                        CANONICAL_URL.setAttribute('href', OG_URL || '');
                    }
                    // Update Twitter meta tags
                    const metaTwitterURL = document.querySelector('meta[property="twitter:url"]');
                    if (metaTwitterURL) {
                        metaTwitterURL.setAttribute('content', OG_URL || '');
                    }

                    const metaTwitterTitle = document.querySelector('meta[property="twitter:title"]');
                    if (metaTwitterTitle) {
                        metaTwitterTitle.setAttribute('content', TITLE || '');
                    }
                    const metaTwitterDescription = document.querySelector('meta[property="twitter:description"]');
                    if (metaTwitterDescription) {
                        metaTwitterDescription.setAttribute('content', DESCRIPTION || '');
                    }
                    const metaTwitterImage = document.querySelector('meta[property="twitter:image"]');
                    if (metaTwitterImage && IMAGE) {
                        metaTwitterImage.setAttribute('content', IMAGE || '');
                    }
                }}
            >

                <title>{TITLE}</title>
                {IMAGE && (
                    <link rel="preload" href={IMAGE} as="image" />
                )}
            </Helmet>
        </HelmetProvider>
    );
}

export default SharedHelmet;
